export enum EnumDialogSelected {
    Daily = "daily",
    Weekly = "weekly",
    Monthly = "monthly",
    Yearly = "yearly",
    Period = "period",
    Reservation = "reservation",
}

export class MenuPeriod {
    daily_time_end: string;
    daily_time_start: string;
    dialog_selected: EnumDialogSelected;
    id: string;
    menu_id: string;
    monthly_day_of_month: number;
    monthly_day_of_week: number;
    monthly_time_end: string;
    monthly_time_start: string;
    monthly_week_of_month: number;
    period_day_from: number;
    period_day_to: number;
    period_month_from: number;
    period_month_to: number;
    period_time_end: string;
    period_time_start: string;
    period_year_from: number;
    period_year_to: number;
    rank: number;
    reservation_after_day: number;
    reservation_before_day: number;
    weekly_day_of_week: number;
    weekly_time_end: string;
    weekly_time_start: string;
    yearly_day_from: number;
    yearly_day_to: number;
    yearly_month_from: number;
    yearly_month_to: number;
    yearly_time_end: string;
    yearly_time_start: string;
    exclude_days: string;
}