

















































import Vue from "vue";
import _ from "lodash";
import moment from "moment";

import store from "../../store/session";
import commonStore from "../../store/common";
import { Category, Effect } from "../../model/menu";
import { TranslateServiceBuilder } from "../../services/Translate/translateServiceBuilder";
import * as constant from "../../common/util/constant";

const MenuDetailPopup = () =>
  import("../../components/compositions/SOMenuDetailPopup.vue");
import "../../components/compositions/SOMenuDetailPopup.vue";
import { Menu } from "../../model/entity/menu";
import { isMenuPeriodAvailable } from "../../common/util/checkPeriod";

const tranlate = TranslateServiceBuilder.Instance()._;
let menuPopup;

export default Vue.component("Menu", {
  components: {
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
    QImage: () => import("../../components/elements/QImage.vue"),

    SOPrice: () => import("../../components/components/SOPrice.vue"),
  },
  mounted() {
    if (!store.state.tableSession.table_session_id) {
      this.$router.push("/error?message=テーブルセッションが取得できません。");
    }
    commonStore.dispatch('fetchHoliday');
    this.intervalCheckAvaiable();
  },
  destroyed() {
    this.interval && clearInterval(this.interval);
  },
  data() {
    return {
      tranlate,
      interval: null,
      random: 1,
    };
  },
  watch: {
    "$route.params.menu_id"() {
      if (menuPopup && !this.$route.params.menu_id) {
        menuPopup.remove();
        menuPopup = null;
      }
    },
  },
  computed: {
    categoryId() {
      return this.$route.params.category_id;
    },
    effectId() {
      return this.$route.query.effect_id || "";
    },
    menus() {
      this.random;
      let menus;
      if (this.effectId) {
        const all = _.find(store.state.effects, (effect) => {
          return effect.id == this.effectId;
        }) as Effect | undefined;
        let menus = [];
        all.category_option &&
          all.category_option.categories.forEach((category) => {
            if (category.id == this.categoryId) {
              menus = [...menus, ...category.menus];
            }
          });
        return menus;
      } else {
        const all = _.find(store.state.categories, (cat) => {
          return cat.id == this.categoryId;
        }) as Category | undefined;

        menus = all.menus;
      }
      const currentTime = moment();
      return _.filter(menus, menu => this.isMenuAvaible(menu, currentTime));
    },
    menuLimitTime: () => (menu) => {
      return `${menu.limit_length}分 (L.O)${menu.limit_last_time}分前`;
    },
  },
  methods: {
    intervalCheckAvaiable() {
      const self = this;
      this.interval = setInterval(function() {
        self.random = Date.now();
      }, constant.TIME_RE_COMPUTED);
      // 30 * 60
    },
    isMenuAvaible(menu: Menu , currentTime): boolean {
      return isMenuPeriodAvailable(menu, currentTime);
    },

    handleClickMenu(menuId: string) {
      this.showMenuDetail(menuId);
    },

    showMenuDetail(menuId: string) {
      MenuDetailPopup().then((Popup) => {
        if (menuPopup) {
          menuPopup.remove();
          menuPopup = null;
        }

        menuPopup = new Popup.default({
          propsData: {
            parent: this.$el,
            isOpen: true,
            menuId,
          },
        });
        menuPopup.$on("update", (order) => {
          store.dispatch("addOrder", {
            order: {
              id: order.menuId,
              quantity: order.quantity,
              options: _.map(order.options, (opt) => {
                return {
                  option_id: opt,
                  quantity: order.quantity,
                };
              }),
            },
            index: -1,
          });
        });

        menuPopup.$on("remove", (opt) => {
          console.log("remove", opt);
          this.$router.push(
            `/menu/${this.$route.params.category_id}?effect_id=${this.effectId}`
          );
        });

        menuPopup.$on("close", () => {
          this.$router.push(
            `/menu/${this.$route.params.category_id}?effect_id=${this.effectId}`
          );
        });

        menuPopup.$mount();
        this.$router.push(
          `/menu/${this.$route.params.category_id}/${menuId}?effect_id=${this.effectId}`
        );
      });
    },
    isDisplaySoldOut(menu: Menu) {
      if ((menu.is_set_maximum_order_per_day && (menu.remain_order < 1)) || (menu.is_soldout)) {
        return true;
      }
      var rtn = false;
      Object.keys(menu.menu_options).forEach(function (i) {
        let menu_option = menu.menu_options[i];
        if (menu_option.type === 'radio') {
          let soldout = [];
          Object.keys(menu_option.menus_menu_options).forEach(function (j) {
            let option_menu = menu_option.menus_menu_options[j].menu;
            soldout.push((option_menu.is_set_maximum_order_per_day && (option_menu.remain_order < 1)) || (option_menu.is_soldout));
          });
          if (soldout.length !== 0) {
            rtn = rtn || soldout.every(function(val){
              return val === true;
            });
          }
        }
      });
      return rtn;
    },
  },
});
