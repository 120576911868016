const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment); // eslint-disable-line
const DAY_HOLIDAY_OPTION = 8;
const EXCLUDE_HOLIDAY_OPTION = 7;
import commonStore from "../../store/common";
import { EnumDialogSelected, MenuPeriod } from "../../model/entity/menu_period";
import { Menu } from "../../model/entity/menu";
export function isMenuPeriodAvailable(menu: Menu, currentTime): boolean {
  if (menu.display_period_option && menu.menu_periods.length) {
    return menu.menu_periods.some((menuPeriod: MenuPeriod) => {
      if (menuPeriod.dialog_selected === EnumDialogSelected.Daily) {
        return isWithinDaily(menuPeriod, currentTime);
      } else if (menuPeriod.dialog_selected === EnumDialogSelected.Weekly) {
        return isWithinWeekly(menuPeriod, currentTime);
      } else if (menuPeriod.dialog_selected === EnumDialogSelected.Monthly) {
        return isWithinMonthly(menuPeriod, currentTime);
      } else if (menuPeriod.dialog_selected === EnumDialogSelected.Yearly) {
        return isWithinYearly(menuPeriod, currentTime);
      } else if (menuPeriod.dialog_selected === EnumDialogSelected.Period) {
        return isWithinPeriod(menuPeriod, currentTime);
      }
    });
  }
  return true;
}

function getTimeRange(startTimeString, endTimeString) {
  return {
    startTime: startTimeString.split(":"),
    endTime: endTimeString.split(":")
  }
}

function nthOfMonth(currentDate) {
  return Math.ceil(currentDate.date() / 7);
}

function isWithinDaily(menuPeriod, currentTime) {
  const { startTime, endTime } = getTimeRange(menuPeriod.daily_time_start, menuPeriod.daily_time_end);
  const start = moment().set({ hour: startTime[0], minute: startTime[1], second: 0 });
  const end = moment().set({ hour: endTime[0], minute: endTime[1], second: 59 });
  return currentTime.within(moment.range(start, end)) && isWithoutExcludeDay(menuPeriod, currentTime);
}

function isWithinWeekly(menuPeriod, currentTime) {
  const { startTime, endTime } = getTimeRange(menuPeriod.weekly_time_start, menuPeriod.weekly_time_end);
  const start = moment().set({ hour: startTime[0], minute: startTime[1], second: 0 });
  const end = moment().set({ hour: endTime[0], minute: endTime[1], second: 59 });
  // case select holiday
  if (menuPeriod.weekly_day_of_week !== DAY_HOLIDAY_OPTION) {
    start.day(menuPeriod.weekly_day_of_week);
    end.day(menuPeriod.weekly_day_of_week);
  }
  return (currentTime.within(moment.range(start, end))
    && isWithInHoliday(menuPeriod, currentTime, 'weekly_day_of_week'))
    && isWithoutExcludeDay(menuPeriod, currentTime);
}

function isWithinMonthly(menuPeriod, currentTime) {
  const { startTime, endTime } = getTimeRange(menuPeriod.monthly_time_start, menuPeriod.monthly_time_end);
  if (menuPeriod.monthly_week_of_month === 6) { // day of month
    const start = moment().date(menuPeriod.monthly_day_of_month).set({ hour: startTime[0], minute: startTime[1], second: 0 });
    const end = moment().date(menuPeriod.monthly_day_of_month).set({ hour: endTime[0], minute: endTime[1], second: 59 });
    return currentTime.within(moment.range(start, end)) && isWithoutExcludeDay(menuPeriod, currentTime);
  } else { // case day of ntd week 
    const start = moment().set({
      hour: startTime[0],
      minute: startTime[1],
      second: 0
    });
    const end = moment().set({
      hour: endTime[0],
      minute: endTime[1],
      second: 59
    });
    if (menuPeriod.monthly_day_of_week !== DAY_HOLIDAY_OPTION) {
      start.day(menuPeriod.monthly_day_of_week);
      end.day(menuPeriod.monthly_day_of_week);
    }
    return menuPeriod.monthly_week_of_month === nthOfMonth(currentTime)
      && isWithInHoliday(menuPeriod, currentTime, 'monthly_day_of_week')
      && currentTime.within(moment.range(start, end)) && isWithoutExcludeDay(menuPeriod, currentTime);
  }
}

function isWithinYearly(menuPeriod, currentTime) {
  const { startTime, endTime } = getTimeRange(menuPeriod.yearly_time_start, menuPeriod.yearly_time_end);
  const start = moment().set({
    date: menuPeriod.yearly_day_from,
    month: menuPeriod.yearly_month_from - 1, // start month 0->11
    hour: startTime[0],
    minute: startTime[1],
    second: 0
  });
  const end = moment().set({
    date: menuPeriod.yearly_day_to,
    month: menuPeriod.yearly_month_to - 1,
    hour: endTime[0],
    minute: endTime[1],
    second: 59
  });
  return currentTime.within(moment.range(start, end)) && isWithoutExcludeDay(menuPeriod, currentTime);
}

function isWithinPeriod(menuPeriod, currentTime) {
  const { startTime, endTime } = getTimeRange(menuPeriod.period_time_start, menuPeriod.period_time_end);
  const start = moment().set({
    date: menuPeriod.period_day_from,
    month: menuPeriod.period_month_from - 1,
    year: menuPeriod.period_year_from,
    hour: startTime[0],
    minute: startTime[1],
    second: 0
  });
  const end = moment().set({
    date: menuPeriod.period_day_to,
    month: menuPeriod.period_month_to - 1,
    year: menuPeriod.period_year_to,
    hour: endTime[0],
    minute: endTime[1],
    second: 59
  });
  return currentTime.within(moment.range(start, end)) && isWithoutExcludeDay(menuPeriod, currentTime);
}
function isWithoutExcludeDay(menuPeriod: MenuPeriod, currentDate) {
  const dayOfWeek = currentDate.day();
  let dayOfWeekServer = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
  if (menuPeriod.exclude_days === '') {
    return true;
  }
  const listExcludeDays = menuPeriod.exclude_days.split(',');
  if (listExcludeDays.includes(EXCLUDE_HOLIDAY_OPTION + "")) {
    return !commonStore.getters.holidays().includes(currentDate.format('YYYY-MM-DD'))
  }
  return !listExcludeDays.includes(dayOfWeekServer + "");
}

function isWithInHoliday(menuPeriod, currentDate, property) {
  if (menuPeriod[property] === 8) {
    return commonStore.getters.holidays().includes(currentDate.format('YYYY-MM-DD'));
  }
  return true;
}